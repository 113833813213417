import React, { Component } from 'react';
import {
  createStyles,
  Theme,
  ThemeProvider as MuiThemeProvider,
  withStyles
} from '@material-ui/core/styles';
import * as Sentry from '@sentry/browser';
import SwipeableViews from 'react-swipeable-views';
import AssociateHub from './AssociateHub';
import RemoveHub from './RemoveHub';
import Header from '../../Common/Headers/Header';
import FinishInstallDialog from '../../Common/Popups/FinishInstallDialog';
import Button from '@material-ui/core/Button';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import unit_todo_theme from '../../Common/Themes/unit_todo_theme';
import Paper from '@material-ui/core/Paper';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import WifiList from './WifiList';
import WifiOffline from './WifiOffline';
import HubInfoList from './HubInfoList';
import AddWifiDevice from './AddWifiDevice';
import Inclusion from './Inclusion';
import HubUpdating from './HubUpdating';
import HubRebooting from './HubRebooting_v2';
import HubRestarting from './HubRestarting';
import {
  isRingDoorbellSku,
  isTransformerSku,
  isChimeSku,
  isDoorWindowSensorSku,
  isRecessedDoorSensorSku,
  isMotionSensorSku,
  isLeakSensorSku,
  clearIntervals,
  setFocusRequiredInterval
} from '../common/utils';
import { resetFabPosition } from '../../Common/utils/commonUtils';

import * as routes from '../unitdetail_apiRoutes';
import client from '../../Common/utils/client';
import * as deviceConstants from '../../Common/Constants/deviceConstants';
import { DEVICE_TYPES_RELATIVE_TO_YALE as yale } from '../../Common/Constants/deviceConstants';
import * as sfConstants from '../../Common/Constants/salesforceConstants';
import {
  SF_Dwelo_DeviceMappings,
  WorkTicketLockDetails
} from '../../Common/Constants/salesforceConstants_v2';
import theme from '../../Common/Themes/tab_theme';
import '../../Common/stylesheets/global.scss';
import axios, { AxiosError, CancelToken, CancelTokenSource } from 'axios';
import * as ldConstants from '../../Common/Constants/launchDarklyConstants';
import {
  GOOGLE_ANALYTICS,
  HUB_CONNECTIVITY_BANNER,
  SUBMIT_EXTRA_LOCK_DATA,
  SUBMIT_FROM_HUB_ASSOCIATION,
  YALE_LOCK_MASTER_CODES
} from '../../Common/Constants/launchDarklyConstants';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { LDFlagSet } from 'launchdarkly-js-sdk-common';
import ReactGA from 'react-ga';
import { RouteComponentProps } from 'react-router';
import { UnitTodoList } from './UnitTodoList';
import {
  appendDataToClosingNotes,
  containsTodoListDeviceToInstall,
  currentBootTimeGreaterThanStored,
  getDiffSecsBetweenNowAndDateString
} from '../common/utils';
import {
  DWELO_CARE_PHONE,
  HUB_STATUS_INTERVAL_TIMEOUT,
  REBOOT_MAX_DURATION_SECS,
  RESTART_MAX_DURATION_SECS
} from '../../Common/Constants/installerAppConstants';
import {
  hubRebootDateStorage,
  hubRestartDateStorage,
  unitTodoMetaStorage,
  setPendingWorkTicket
} from '../../Common/utils/storageInterface';
import {
  Community,
  DeviceSensor,
  DeviceToBeInstalled,
  Unit,
  UpdateStatus,
  User,
  V4_Hub_Status,
  V4_SF_Unit_Configuration,
  V4_Update_Status
} from '../../Common/Types/cloudApi';
import StatusBar from '../../Common/Headers/StatusBar';
import { defaultPaletteOptions } from '../../Common/Themes/dweloPalette';
import { GetAddressSyncStatusResponse } from '../../Common/CodeSyncing/apiService/types';
import blankTheme from '../../Common/Themes/blankTheme';
import { HubConnectivityBanner } from '../../Common/HubConnectivity/HubConnectivityBanner';
import { captureExceptionIfNot424 } from '../../Common/utils/errorHandling';

type TabContainerParams = {
  children: JSX.Element;
  dir: string;
  tabNumber: number;
};

function TabContainer({ children, dir, tabNumber }: TabContainerParams) {
  let padding = tabNumber === 0 ? { paddingBottom: '24px' } : { padding: '24px' };
  return (
    <Typography component="div" dir={dir} style={padding}>
      {children}
    </Typography>
  );
}

const styles = (theme: Theme) =>
  createStyles({
    button: {
      display: 'block',
      left: '50%',
      marginLeft: '-40px',
      bottom: '90px',
      position: 'fixed',
      backgroundColor: '#4ad4d4'
    },
    fab: {
      position: 'fixed',
      bottom: '16px',
      backgroundColor: '#4ad4d4'
    },
    orange: {
      backgroundColor: '#F6861E',
      boxShadow: '0 -6px 0 #F6861E, 0 1px 6px rgba(0,0,0, .35)'
    },
    textField: {
      marginTop: theme.spacing(3),
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
      width: '100%'
    },
    tabSize: {
      fontSize: '16px'
    },
    include: {
      marginBottom: '64px'
    },
    exclude: {
      backgroundColor: '#991210'
    },
    finishButtonYale: {
      position: 'fixed',
      bottom: '16px',
      backgroundColor: '#4ad4d4'
    },
    phoneNumber: {
      color: defaultPaletteOptions.secondary.main
    }
  });

type Params = {
  communityId: string;
  unitId: string;
};

type State = {
  devices: Array<DeviceSensor>;
  doesWTHaveTodoListDevice: boolean;
  unit: Unit;
  value: number;
  page: string;
  alertInclude: boolean;
  alertExclude: boolean;
  showFinishInstallDialog: boolean;
  installStatusRadio: string;
  incompleteReason: string;
  privacyLockInstalled: boolean;
  addOnSwitchesInstalled: boolean;
  addOnSwitchesQty: string;
  transformerInstalled: boolean;
  transformerNeeded: boolean;
  hubLocationText: string;
  closingNotesText: string;
  waitingSubmitResponse: boolean;
  yaleLock: string;
  wifiDevice?: any;
  sensorInterval?: NodeJS.Timeout;
  updateStatusInterval?: NodeJS.Timeout;
  hubStatusInterval?: NodeJS.Timeout;
  status: V4_Hub_Status & {
    gateway_id: Unit['gatewayId'];
  };
  codeSyncingData: GetAddressSyncStatusResponse['syncs'];
};

type Props = RouteComponentProps<Params> & {
  flags: LDFlagSet;
  unit: Unit;
  devices: Array<DeviceSensor>;
  classes: any;
  theme: any;
  updating?: UpdateStatus['update_pending'];
  rebooting?: boolean;
  restarting?: boolean;
  community: Community;
  user: User;
  initial_status: V4_Update_Status | null;
  hubStatus: V4_Hub_Status | null;
  sfConfiguration: V4_SF_Unit_Configuration;
  wtData: {
    devicesToBeInstalled: Array<DeviceToBeInstalled>;
    incompleteReasons: Array<string>;
    workTicketAvailable: boolean;
  };
  codeSyncingData: GetAddressSyncStatusResponse['syncs'];
};

class UnitDetail extends Component<Props, State> {
  __source: CancelTokenSource;
  __cancel: { cancelToken: CancelToken };
  classes: any;
  theme: any;
  state: State;

  constructor(props: Props) {
    super(props);
    const { classes, theme } = this.props;
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const cancel = {
      cancelToken: source.token
    };
    this.__source = source;
    this.__cancel = cancel;
    this.classes = classes;
    this.theme = theme;
    this.startInclusion = this.startInclusion.bind(this);
    this.addWifiDevice = this.addWifiDevice.bind(this);
    this.returnFunction = this.returnFunction.bind(this);
    this.returnToUnits = this.returnToUnits.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.state = {
      unit: this.props.unit,
      devices: this.props.devices,
      doesWTHaveTodoListDevice: containsTodoListDeviceToInstall(
        this.props.wtData.devicesToBeInstalled
      ),
      value: 0,
      page: 'detail',
      alertInclude: false,
      alertExclude: false,
      showFinishInstallDialog: false,
      installStatusRadio: '',
      incompleteReason: '',
      privacyLockInstalled: false,
      addOnSwitchesInstalled: false,
      addOnSwitchesQty: '',
      transformerInstalled: false,
      transformerNeeded: this.getSalesforceTransformer(),
      hubLocationText: '',
      closingNotesText: '',
      waitingSubmitResponse: false,
      yaleLock: yale.UNKNOWN,
      codeSyncingData: this.props.codeSyncingData,
      status: {
        hub_serial_number: 'Loading...',
        gateway_id: this.props.unit.gatewayId,
        balena_commit_hash: 'Loading...',
        balena_branch: 'Loading...',
        balena_release_group: 'Loading...',
        boot_time: 'Loading...',
        internet_delivery: 'Loading...',
        ethernet_ip_address: 'Loading...',
        wifi_ip_address: 'Loading...',
        cellular_ip_address: 'Loading...',
        cellular_signal: 'Loading...',
        iccid: 'Loading...',
        last_seen: 'Loading...',
        ...this.props.hubStatus
      }
    };
  }

  closeDialog() {
    this.setState({ alertExclude: false, alertInclude: false });
  }

  refreshSensors = (): void => {
    client
      .get(routes.SENSOR(this.props.unit.hub_serial), this.__cancel)
      .then((res) => {
        let data = res.data.results;
        if (data.length && data !== this.state.devices) {
          this.setState(
            {
              devices: data
            },
            () => {
              this.setState({
                wifiDevice: this.getWifiDeviceOrNull()
              });
            }
          );
        }
      })
      .catch(function (thrown) {
        if (axios.isCancel(thrown)) {
          console.log('Request canceled', thrown.message);
        } else {
          Sentry.captureException(thrown);
          return false;
        }
      });
  };

  refreshSensorsAsync = async (): Promise<AxiosError | void> => {
    try {
      const res = await client.get(routes.SENSOR(this.props.unit.hub_serial), this.__cancel);
      let data = res.data.results;
      if (data.length && data !== this.state.devices) {
        this.setState(
          {
            devices: data
          },
          () => {
            this.setState({
              wifiDevice: this.getWifiDeviceOrNull()
            });
          }
        );
      }
      return Promise.resolve();
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Request canceled', error.message);
      } else {
        Sentry.captureException(error);
        throw error;
      }
    }
  };

  refreshUpdates = () => {
    client
      .get(routes.UPDATE_STATUS(this.props.unit.hub_serial), this.__cancel)
      .then((res) => {
        let data = res.data.result;
        if (data?.update_pending) {
          window.location.reload();
        }
        return Promise.resolve();
      })
      .catch(function (thrown) {
        if (axios.isCancel(thrown)) {
          console.log('Request canceled', thrown.message);
        } else {
          captureExceptionIfNot424(thrown);
          return false;
        }
      });
  };

  setAllIntervals() {
    const newState: Pick<
      State,
      'sensorInterval' | 'updateStatusInterval' | 'hubStatusInterval'
    > = {} as const;
    if (!this.state.sensorInterval) {
      newState.sensorInterval = setFocusRequiredInterval(
        this.refreshSensors,
        parseInt(process.env.REACT_APP_SENSOR_POLL as string, 10)
      );
    }
    if (!this.state.updateStatusInterval) {
      newState.updateStatusInterval = setFocusRequiredInterval(this.refreshUpdates, 10000);
    }
    if (!this.state.hubStatusInterval) {
      newState.hubStatusInterval = this.createHubStatusInterval();
    }
    this.setState(newState);
  }

  createHubStatusInterval = () => {
    return setFocusRequiredInterval(this.getHubStatus, HUB_STATUS_INTERVAL_TIMEOUT);
  };

  getWifiDeviceOrNull() {
    if (this.state.devices.length === 0 || this.state.devices.length === 1) {
      return null;
    }
    let devices = this.state.devices;
    for (let i = 0; i < devices.length; i++) {
      const model = devices[i].model;
      if (model && deviceConstants.WIFI_DEVICES.includes(model)) {
        return devices[i];
      }
    }
    return null;
  }

  componentDidMount() {
    resetFabPosition();
    let hubLocationText;
    const installerPreviewData = this.props.unit[
      sfConstants.SharedVars.INSTALLER_PREVIEW_DATA
    ];
    if (installerPreviewData) {
      hubLocationText = installerPreviewData[sfConstants.SharedVars.HUB_LOCATION];
    }

    // Set localstorage item to indicate that there is an open work ticket if work ticket is not complete or incomplete
    if (
      installerPreviewData &&
      this.props.unit.hub_serial &&
      this.props.flags[ldConstants.ENFORCE_WORK_TICKET_CLOSURE] &&
      !sfConstants.WorkTicket.SUBMITTED_STATUSES.includes(
        installerPreviewData[sfConstants.SharedVars.WORK_TICKET_STATUS]
      )
    ) {
      setPendingWorkTicket(
        this.props.unit.uid,
        this.props.unit.unit,
        this.props.unit.communityId,
        this.props.unit.communityName,
        `/community/${this.props.unit.communityId}/unit/${this.props.unit.uid}`,
        routes.GET_WORK_TICKET_DATA(this.props.unit.uid)
      );
    }

    this.setState({
      wifiDevice: this.getWifiDeviceOrNull(),
      hubLocationText: hubLocationText ? hubLocationText : ''
    });
    if (this.props.unit.hub_serial && this.props.devices.length) {
      if (!this.props.updating) {
        this.setAllIntervals();
      } else {
        this.setState({ hubStatusInterval: this.createHubStatusInterval() });
      }
    }

    // If on the AssociateHub component
    if (
      this.props.flags[SUBMIT_FROM_HUB_ASSOCIATION] &&
      this.props.unit.gatewayId !== undefined &&
      !this.props.unit.gatewayId
    ) {
      this.setState({ installStatusRadio: 'incomplete' });
    }
  }

  /**
   * This function is used for positioning finish button and fabs,
   * depending on the feature flag.
   * This function can be removed once we no longer use the feature flag.
   */
  componentDidUpdate(prevProps: Props, prevState: State, snapshot: any) {
    if (prevProps.flags[YALE_LOCK_MASTER_CODES] !== this.props.flags[YALE_LOCK_MASTER_CODES]) {
      resetFabPosition();
    }
  }

  componentWillUnmount() {
    clearIntervals([
      this.state.sensorInterval,
      this.state.updateStatusInterval,
      this.state.hubStatusInterval
    ]);
    if (this.props.unit.hub_serial && this.props.devices.length) {
      this.__source.cancel('Operation canceled by the user.');
    }
  }

  clearUnitDetailSpecificIntervals = () => {
    clearIntervals([this.state.sensorInterval, this.state.updateStatusInterval]);
    this.setState({
      sensorInterval: undefined,
      updateStatusInterval: undefined
    });
  };

  getHubStatus = () => {
    client
      .get(routes.HUB_STATUS(this.props.unit.hub_serial))
      .then((res) => {
        res.data.gateway_id = this.props.unit.gatewayId;
        this.setState({ status: res.data });
      })
      .catch((err) => {
        Sentry.captureException(err);
      });
  };

  handleChange = (event: React.MouseEvent, value: number) => {
    this.setState({ value }, function () {
      resetFabPosition();
    });
  };
  getSalesforceTransformer() {
    let transformer = this.props.wtData.devicesToBeInstalled.find((device) => {
      return device.modelNumber === 'DOORBELL_TRANSFORMER';
    });
    return !!transformer;
  }

  handleChangeIndex = (index: number) => {
    this.setState({ value: index }, function () {
      resetFabPosition();
    });
  };

  handleFinishDialogClose = () => {
    this.setState({ showFinishInstallDialog: false });
  };

  handleFinishDialogOpen = () => {
    this.setState({ showFinishInstallDialog: true });
  };

  handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let { name, value, type, checked } = event.target;
    if (type === 'checkbox') {
      this.setState({ [name]: checked } as any);
    } else {
      this.setState({ [name]: value } as any);
    }
  };

  handleAlerts = () => {
    if (!this.state.installStatusRadio) {
      alert('Please select Complete or Incomplete');
      return;
    } else if (
      this.state.installStatusRadio === 'incomplete' &&
      !this.state.incompleteReason
    ) {
      alert('Please select an Incomplete reason');
      return;
    }
    if (this.state.addOnSwitchesInstalled) {
      if (!this.state.addOnSwitchesQty) {
        alert('Please enter the quantity of add-on switches installed');
        return;
      } else if (!Number.isInteger(parseInt(this.state.addOnSwitchesQty))) {
        alert('The add-on switches quantity must be a whole number');
        return;
      } else if (Number(this.state.addOnSwitchesQty) <= 0) {
        alert('The add-on switches quantity must be greater than 0');
        return;
      }
    }
    if (!this.state.closingNotesText) {
      alert('Please fill in the Closing Notes');
      return;
    }
  };

  handleSubmit = (extraLockData?: WorkTicketLockDetails) => {
    this.handleAlerts();

    let data = {};

    const installerPreviewData = this.props.unit[
      sfConstants.SharedVars.INSTALLER_PREVIEW_DATA
    ];

    if (installerPreviewData) {
      data[sfConstants.SharedVars.WORK_TYPE] =
        installerPreviewData[sfConstants.SharedVars.WORK_TYPE];
    } else {
      data[sfConstants.SharedVars.WORK_TYPE] = sfConstants.SharedVars.SERVICE;
    }
    const unitCache = unitTodoMetaStorage.getValid(this.state.unit.uid);
    const newClosingNotes = appendDataToClosingNotes(this.state.closingNotesText, {
      ringTroubleshootInfo: unitCache.ringDoorbell?.troubleshootInfo
    });

    data[sfConstants.SharedVars.WORK_TICKET_FIELDS] = {
      [sfConstants.WorkTicket.STATUS__C]: this.state.installStatusRadio,
      [sfConstants.WorkTicket.NOTES__C]: newClosingNotes,
      [sfConstants.WorkTicket.BATTERIES_REPLACED__C]: extraLockData?.batteriesReplaced,
      [sfConstants.WorkTicket.LOCK_THROW_STATUS__C]: extraLockData?.lockThrowStatus,
      [sfConstants.WorkTicket.LOCK_THROW_DETAILS__C]: extraLockData?.lockThrowDetails,
      [sfConstants.WorkTicket.MASTER_CODE_SYNCED__C]: extraLockData?.masterCodeSynced,
      [sfConstants.WorkTicket.PINS_SYNCED__C]: extraLockData?.pinsSynced,
      [sfConstants.WorkTicket.DID_YOU_LEAVE_DEVICES_ON_SITE__C]: 'No',
      [sfConstants.WorkTicket.DID_YOU_TAKE_DEVICES_FROM_THE_SITE__C]: 'No' // both No's are hard coded for now
    };

    if (this.state.installStatusRadio === 'incomplete') {
      data[sfConstants.SharedVars.WORK_TICKET_FIELDS][
        sfConstants.WorkTicket.INCOMPLETE_REASON__C
      ] = this.state.incompleteReason;
    }

    data[sfConstants.SharedVars.UNIT_FIELDS] = {};

    if (this.state.hubLocationText) {
      data[sfConstants.SharedVars.UNIT_FIELDS][
        sfConstants.Unit.HUB_LOCATION__C
      ] = this.state.hubLocationText;
    }
    const devicesToBeInstalled = this.props.wtData[
      sfConstants.SharedVars.DEVICES_TO_BE_INSTALLED
    ];

    const { community } = this.props;
    const { SALESFORCE_INFO } = sfConstants.SharedVars;
    const { ID } = sfConstants.SharedVars;
    const DOORBELL = 'doorbell';
    const CHIME = 'chime';
    const TRANSFORMER = 'transformer';
    const DOOR_WINDOW_SENSOR = 'doorWindowSensor';
    const MOTION_SENSOR = 'motionSensor';
    const RECESSED_DOOR_SENSOR = 'recessedDoorSensor';
    const LEAK_SENSOR = 'leakSensor';

    if (this.state.privacyLockInstalled) {
      let privacyLock = devicesToBeInstalled.find((device) => {
        return (
          device[sfConstants.SharedVars.DEVICE_TYPE] === sfConstants.DeviceTypes.PRIVACY_LOCK
        );
      });

      if (privacyLock) {
        privacyLock = privacyLock[sfConstants.SharedVars.ID];
      } else {
        const { PRIVACY_LOCK } = sfConstants.SharedVars;

        if (
          community &&
          community[SALESFORCE_INFO] &&
          community[SALESFORCE_INFO][PRIVACY_LOCK] &&
          community[SALESFORCE_INFO][PRIVACY_LOCK][ID]
        ) {
          privacyLock = community[SALESFORCE_INFO][PRIVACY_LOCK][ID];
        } else {
          privacyLock = sfConstants.DEFAULT_PRIVACY_LOCK_SKU;
        }
      }

      data[sfConstants.SharedVars.UNIT_FIELDS][sfConstants.Unit.PRIVACY_LOCK_QTY__C] = 1;
      data[sfConstants.SharedVars.UNIT_FIELDS][sfConstants.Unit.PRIVACY_LOCK__C] = privacyLock;
    }

    if (this.state.addOnSwitchesInstalled) {
      data[sfConstants.SharedVars.UNIT_FIELDS][
        sfConstants.Unit.ADD_ON_SWITCH_QTY__C
      ] = this.state.addOnSwitchesQty;

      let addOnSwitch = devicesToBeInstalled.find((device) => {
        return (
          device[sfConstants.SharedVars.DEVICE_TYPE] === sfConstants.DeviceTypes.ADD_ON_SWITCH
        );
      });

      if (addOnSwitch) {
        addOnSwitch = addOnSwitch[sfConstants.SharedVars.ID];
      } else {
        const { ADD_ON_SWITCH } = sfConstants.SharedVars;

        if (
          community &&
          community[SALESFORCE_INFO] &&
          community[SALESFORCE_INFO][ADD_ON_SWITCH] &&
          community[SALESFORCE_INFO][ADD_ON_SWITCH][ID]
        ) {
          addOnSwitch = community[SALESFORCE_INFO][ADD_ON_SWITCH][ID];
        } else {
          addOnSwitch = sfConstants.DEFAULT_ADD_ON_SWITCH_SKU;
        }
      }

      data[sfConstants.SharedVars.UNIT_FIELDS][
        sfConstants.Unit.ADD_ON_SWITCH_SKU__C
      ] = addOnSwitch;
    }
    // For each array, the first index is the sku field, and second is the quantity field.
    const deviceTypeToUnitFields = {
      [sfConstants.DeviceTypes.LOCK]: [sfConstants.Unit.LOCK__C, sfConstants.Unit.LOCK_QTY__C],
      [sfConstants.DeviceTypes.THERMOSTAT]: [
        sfConstants.Unit.THERMOSTAT__C,
        sfConstants.Unit.THERMOSTAT_QTY__C
      ],
      [sfConstants.DeviceTypes.PRIMARY_SWITCH]: [
        sfConstants.Unit.PIMARY_SWITCH_SKU__C,
        sfConstants.Unit.PRIMARY_SWITCHES_QTY__C
      ],
      [sfConstants.DeviceTypes.OUTLET]: [
        sfConstants.Unit.OUTLET__C,
        sfConstants.Unit.OUTLET_QTY__C
      ],
      [sfConstants.DeviceTypes.VOICE_CONTROL]: [
        sfConstants.Unit.VOICE_CONTROL__C,
        sfConstants.Unit.VOICE_CONTROL_QTY__C
      ],
      //salesforce doesn't have explicit device types for the following
      [DOORBELL]: [sfConstants.Unit.DOORBELL_SKU__C, sfConstants.Unit.DOORBELL_QTY__C],
      [CHIME]: [sfConstants.Unit.CHIME_SKU__C, sfConstants.Unit.CHIME_QTY__C],
      [TRANSFORMER]: [
        sfConstants.Unit.TRANSFORMER_SKU__C,
        sfConstants.Unit.TRANSFORMER_QTY__C
      ],
      [LEAK_SENSOR]: [
        sfConstants.Unit.LEAK_SENSOR_SKU__C,
        sfConstants.Unit.LEAK_SENSOR_QTY__C
      ],
      [DOOR_WINDOW_SENSOR]: [
        sfConstants.Unit.DOOR_WINDOW_SENSOR__C,
        sfConstants.Unit.DOOR_WINDOW_SENSOR_QTY__C
      ],
      [MOTION_SENSOR]: [
        sfConstants.Unit.MOTION_SENSOR__C,
        sfConstants.Unit.MOTION_SENSOR_QTY__C
      ],
      [RECESSED_DOOR_SENSOR]: [
        sfConstants.Unit.RECESSED_DOOR_SENSOR__C,
        sfConstants.Unit.RECESSED_DOOR_SENSOR_QTY__C
      ]
    };

    let deviceQuantities = {}; // key is the dwelo device type, value is an int

    // populates deviceQuantities
    if (this.props.flags[ldConstants.SALESFORCE_UNIT_SKU_QUANTITY]) {
      this.state.devices.forEach((device) => {
        const deviceType = device.deviceType;
        if (deviceType !== deviceConstants.GATEWAY) {
          if (deviceType in deviceQuantities) {
            deviceQuantities[deviceType]++;
          } else {
            deviceQuantities[deviceType] = 1;
          }
        }
      });
    }

    // Adds the devices that were "installed" to the post data
    // to be sent to the Salesforce unit record.
    devicesToBeInstalled.forEach((device) => {
      const salesforceDeviceType = device[sfConstants.SharedVars.DEVICE_TYPE];
      if (salesforceDeviceType && deviceTypeToUnitFields[salesforceDeviceType]) {
        const skuField = deviceTypeToUnitFields[salesforceDeviceType][0];
        const quantityField = deviceTypeToUnitFields[salesforceDeviceType][1];
        const quantity = this.props.flags[ldConstants.SALESFORCE_UNIT_SKU_QUANTITY]
          ? deviceQuantities[SF_Dwelo_DeviceMappings[salesforceDeviceType]] // quantity from z-wave list component
          : device[sfConstants.SharedVars.QUANTITY]; // quantity from salesforce work ticket

        if (quantity) {
          data[sfConstants.SharedVars.UNIT_FIELDS][skuField] =
            device[sfConstants.SharedVars.ID];
          data[sfConstants.SharedVars.UNIT_FIELDS][quantityField] = quantity;
        }
      }
    });

    // Adds the devices that were "installed" to the post data
    // to be sent to the Salesforce unit record.
    devicesToBeInstalled.forEach((device) => {
      let deviceType = device[sfConstants.SharedVars.DEVICE_TYPE];
      //checking if the device is a ring device or sensor
      if (isChimeSku(device)) {
        deviceType = CHIME;
      } else if (isTransformerSku(device)) {
        deviceType = TRANSFORMER;
      } else if (isRingDoorbellSku(device)) {
        deviceType = DOORBELL;
      } else if (isDoorWindowSensorSku(device)) {
        deviceType = DOOR_WINDOW_SENSOR;
      } else if (isRecessedDoorSensorSku(device)) {
        deviceType = RECESSED_DOOR_SENSOR;
      } else if (isMotionSensorSku(device)) {
        deviceType = MOTION_SENSOR;
      } else if (isLeakSensorSku(device)) {
        deviceType = LEAK_SENSOR;
      }

      if (deviceType && deviceTypeToUnitFields[deviceType]) {
        const skuField = deviceTypeToUnitFields[deviceType][0];
        const quantityField = deviceTypeToUnitFields[deviceType][1];
        data[sfConstants.SharedVars.UNIT_FIELDS][skuField] = device[sfConstants.SharedVars.ID];
        data[sfConstants.SharedVars.UNIT_FIELDS][quantityField] =
          device[sfConstants.SharedVars.QUANTITY];
      }
    });

    this.setState({ waitingSubmitResponse: true });
    this.submitWorkTicket(data);
  };

  submitWorkTicket = (data) => {
    const unitId = this.props.match.params.unitId;
    client
      .post(routes.SUBMIT_WORK_TICKET(unitId), data)
      .then((res) => {
        const communityId = this.props.match.params.communityId;
        this.props.history.push('/community/' + communityId);

        if (this.props.flags[GOOGLE_ANALYTICS]) {
          ReactGA.event({
            category: 'Unit',
            action: 'Install Submitted',
            label: 'Community: ' + this.props.match.params.communityId + ', Unit: ' + unitId
          });
        }
      })
      .catch((res) => {
        this.setState({ waitingSubmitResponse: false });
        Sentry.captureException(res);
        alert('An error occurred. Please try again.');
        if (this.props.flags[GOOGLE_ANALYTICS]) {
          ReactGA.event({
            category: 'Unit',
            action: 'Install Submission Failed',
            label:
              'Community: ' +
              this.props.match.params.communityId +
              ', Unit: ' +
              unitId +
              ', ErrorCode: ' +
              res.status +
              ', Error: ' +
              res.statusText
          });
        }
      });
  };

  addWifiDevice(e) {
    e.preventDefault();
    this.clearUnitDetailSpecificIntervals();
    this.setState({ page: 'nest' });
  }

  getWifiOffline = () => {
    this.clearUnitDetailSpecificIntervals();
    this.setState({ page: 'wifi_offline' });
  };

  determineYaleLock = (elementName) => {
    if (elementName === yale.UNKNOWN) {
      return yale.UNKNOWN;
    }

    // if 'yale' is in the device model name from salesforce
    return elementName.toLowerCase().indexOf('yale') === -1 ? yale.NOT_YALE : yale.IS_YALE;
  };

  startInclusion(e) {
    e.preventDefault();
    this.clearUnitDetailSpecificIntervals();
    let newState = { page: 'inclusion' };
    if (this.props.flags[YALE_LOCK_MASTER_CODES]) {
      newState['yaleLock'] = this.determineYaleLock(e.currentTarget.name);
    }
    this.setState(newState);
  }

  startExclusion = (e) => {
    this.clearUnitDetailSpecificIntervals();
    let newState = { page: 'exclusion' };
    if (this.props.flags[YALE_LOCK_MASTER_CODES]) {
      newState['yaleLock'] = this.determineYaleLock(e.currentTarget.name);
    }
    this.setState(newState);
  };

  showBackgroundColorForMainTab = () => {
    return (
      this.state.page === 'detail' &&
      this.state.value === 0 &&
      this.state.doesWTHaveTodoListDevice &&
      !(this.props.rebooting || this.props.restarting || this.props.updating)
    );
  };

  returnFunction(value = 0, device: DeviceSensor | null = null, removeId = null) {
    this.setAllIntervals();
    if (!device && !removeId) {
      this.setState({ page: 'detail', value: value }, function () {
        resetFabPosition();
      });
    } else if (device) {
      let devices = this.state.devices;
      devices.push(device);
      this.setState(
        {
          page: 'detail',
          devices: devices,
          wifiDevice: device,
          value: value
        },
        function () {
          resetFabPosition();
        }
      );
    } else if (removeId) {
      let newDevices = this.state.devices.filter(function (obj) {
        return obj.uid !== removeId;
      });
      this.setState(
        {
          page: 'detail',
          devices: newDevices,
          wifiDevice: null,
          value: value
        },
        function () {
          resetFabPosition();
        }
      );
    }
  }

  openFinishDialog = () => this.setState({ showFinishInstallDialog: true });

  returnToUnits() {
    if (
      this.props.unit.installerPreviewData?.workTicketStatus !==
      sfConstants.WorkTicket.COMPLETE
    ) {
      this.handleFinishDialogOpen();
    } else {
      this.props.history.push(`/community/${this.props.match.params.communityId}`);
    }
  }
  getNestPage() {
    return (
      <AddWifiDevice
        history={this.props.history}
        user={this.props.user}
        unit={this.props.unit}
        device={this.state.wifiDevice}
        return={this.returnFunction}
      />
    );
  }

  getWifiOfflinePage = () => {
    return (
      <WifiOffline
        history={this.props.history}
        user={this.props.user}
        unit={this.props.unit}
        device={this.state.wifiDevice}
        return={this.returnFunction}
      />
    );
  };

  getInclusionPage(exclusion) {
    return (
      <Inclusion
        history={this.props.history}
        user={this.props.user}
        unit={this.props.unit}
        devices={this.state.devices}
        return={this.returnFunction}
        exclusion={exclusion}
        yaleLock={this.state.yaleLock}
        sfConfiguration={this.props.sfConfiguration}
        devicesToBeInstalled={this.props.wtData.devicesToBeInstalled}
        isIotHub={this.props.hubStatus?.is_iot_hub ?? false}
      />
    );
  }

  getUnitTodoList = (finishInstallDialog: JSX.Element | undefined) => {
    return (
      <UnitTodoList
        unit={this.state.unit}
        community={this.props.community}
        bootTime={this.state.status.boot_time}
        devices={this.state.devices}
        devicesToPair={this.props.wtData.devicesToBeInstalled}
        startInclusion={this.startInclusion}
        startExclusion={this.startExclusion}
        submitWorkTicket={this.handleSubmit}
        refreshSensors={this.refreshSensorsAsync}
        codeSyncingData={this.state.codeSyncingData}
        hubConnectivityBanner={this.getHubConnectivityBanner()}
        // We need to send this down because UnitTodoList has state that UnitDetail doesn't have.
        // We can't easily lift state because it would require lots of refactoring and re-testing,
        // although lifting state may not be the right call. A custom hook for handling all the
        // finish dialog stuff might work best in the future.
        FinishInstallDialog={finishInstallDialog}
      />
    );
  };

  getUnitTodoError = () => {
    return (
      <>
        <StatusBar message={'Something went wrong'} error />
        <div style={{ padding: '24px' }}>
          <Typography paragraph>Device details are missing for this unit.</Typography>
          <Typography>
            Please contact DweloCare at{' '}
            <a href={`tel:+1-${DWELO_CARE_PHONE}`} className={this.classes.phoneNumber}>
              {DWELO_CARE_PHONE}
            </a>{' '}
            or chat below to get the device details for this unit.
          </Typography>
        </div>
      </>
    );
  };

  getHubConnectivityBanner = () => {
    if (this.props.flags[HUB_CONNECTIVITY_BANNER]) {
      return (
        <HubConnectivityBanner
          cellularSignal={
            Number.isInteger(this.state.status.cellular_signal)
              ? (this.state.status.cellular_signal as number)
              : null
          }
          connectedToCellular={!!this.state.status.cellular_ip_address}
          connectedToEthernet={!!this.state.status.ethernet_ip_address}
          connectedToWiFi={!!this.state.status.wifi_ip_address}
        />
      );
    } else {
      return null;
    }
  };

  getReturn() {
    let finishButton;
    let finishInstallDialog;
    const extraLockDataFlag = this.props.flags[SUBMIT_EXTRA_LOCK_DATA];

    const hubConnectivityBanner = this.getHubConnectivityBanner();

    if (this.props.wtData.workTicketAvailable) {
      finishButton = (
        <Button
          id={'finish-button'}
          variant="contained"
          size="medium"
          onClick={this.handleFinishDialogOpen}
          className={`keep-fixed ${this.classes.fab}`}
        >
          Finish
        </Button>
      );
      finishInstallDialog = (
        <FinishInstallDialog
          open={this.state.showFinishInstallDialog}
          installStatusRadio={this.state.installStatusRadio}
          incompleteReason={this.state.incompleteReason}
          incompleteReasons={this.props.wtData.incompleteReasons}
          privacyLockInstalled={this.state.privacyLockInstalled}
          addOnSwitchesInstalled={this.state.addOnSwitchesInstalled}
          addOnSwitchesQty={this.state.addOnSwitchesQty}
          transformerInstalled={this.state.transformerInstalled}
          transformerNeeded={this.state.transformerNeeded}
          hubLocationText={this.state.hubLocationText}
          closingNotesText={this.state.closingNotesText}
          waitingSubmitResponse={this.state.waitingSubmitResponse}
          handleClose={this.handleFinishDialogClose}
          handleInputChange={this.handleInputChange}
          handleSubmit={this.handleSubmit}
        />
      );
    }

    let returnhtml = (
      <div>
        <MuiThemeProvider theme={theme}>
          {hubConnectivityBanner}
          <Header
            elevation={true}
            headerText={this.props.unit.unit}
            back={true}
            return={this.returnToUnits}
            arrow={true}
            user={this.props.user}
            history={this.props.history}
            unit={this.props.unit}
            community={this.props.community}
            unitmenu={this.state.value}
          />
          <Paper square className={this.classes.orange} elevation={0}>
            <Tabs
              value={this.state.value}
              onChange={this.handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
            >
              <Tab label="Devices" classes={{ root: this.classes.tabSize }} />
              <Tab label="Nest" classes={{ root: this.classes.tabSize }} />
              <Tab label="HUB" classes={{ root: this.classes.tabSize }} />
            </Tabs>
          </Paper>
          <SwipeableViews
            axis={this.theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={this.state.value}
            onChangeIndex={this.handleChangeIndex}
            containerStyle={{
              // Workaround until https://github.com/oliviertassinari/react-swipeable-views/issues/599 is fixed.
              // The bug is causing no transition for first swipe/tab change on Chrome,
              // so this is hard-coding the transition value that is always used.
              transition: 'transform 0.35s cubic-bezier(0.15, 0.3, 0.25, 1) 0s'
            }}
          >
            <TabContainer dir={this.theme.direction} tabNumber={this.state.value}>
              <MuiThemeProvider theme={unit_todo_theme}>
                {/*We only show UnitTodoList if the salesforce work ticket has support devices to install,*/}
                {/*even if there are devices paired in the unit, because this screen is for Installs and not Services.*/}
                {this.state.doesWTHaveTodoListDevice
                  ? this.getUnitTodoList(extraLockDataFlag ? finishInstallDialog : undefined)
                  : this.getUnitTodoError()}
              </MuiThemeProvider>
            </TabContainer>
            <TabContainer dir={this.theme.direction} tabNumber={this.state.value}>
              <WifiList
                history={this.props.history}
                device={this.state.wifiDevice}
                unit={this.props.unit}
                onClick={this.addWifiDevice}
                offline={this.getWifiOffline}
              />
            </TabContainer>
            <TabContainer dir={this.theme.direction} tabNumber={this.state.value}>
              <HubInfoList status={this.state.status} />
            </TabContainer>
          </SwipeableViews>
        </MuiThemeProvider>
        <Fab
          color="primary"
          aria-label="Add"
          className={`${this.classes.include} ${this.classes.fab} keep-fixed`}
          onClick={this.addWifiDevice}
          style={{
            display: this.state.value === 1 && !this.state.wifiDevice ? 'block' : 'none'
          }}
        >
          <AddIcon />
        </Fab>
        {finishButton}
        {(!this.state.doesWTHaveTodoListDevice || !extraLockDataFlag) && finishInstallDialog}
      </div>
    );
    if (this.props.unit.gatewayId !== undefined && !this.props.unit.gatewayId) {
      let finishDialogProps = {};
      if (this.props.flags[ldConstants.SUBMIT_FROM_HUB_ASSOCIATION]) {
        finishDialogProps = {
          showFinishInstallDialog: this.state.showFinishInstallDialog,
          installStatusRadio: this.state.installStatusRadio,
          incompleteReason: this.state.incompleteReason,
          incompleteReasons: this.props.wtData.incompleteReasons,
          workTicketAvailable: this.props.wtData.workTicketAvailable,
          closingNotesText: this.state.closingNotesText,
          waitingSubmitResponse: this.state.waitingSubmitResponse,
          handleClose: this.handleFinishDialogClose,
          handleInputChange: this.handleInputChange,
          handleSubmit: this.handleSubmit,
          handleOpen: this.handleFinishDialogOpen
        };
      }
      returnhtml = (
        // @ts-ignore
        <AssociateHub
          history={this.props.history}
          user={this.props.user}
          unit={this.props.unit}
          match={this.props.match}
          {...finishDialogProps}
        />
      );
    } else if (this.props.unit.gatewayId && !this.props.unit.hub_serial) {
      if (this.state.installStatusRadio !== 'incomplete') {
        this.setState({ installStatusRadio: 'incomplete' });
      }
      const finishDialogProps = {
        showFinishInstallDialog: this.state.showFinishInstallDialog,
        installStatusRadio: this.state.installStatusRadio,
        incompleteReason: this.state.incompleteReason,
        incompleteReasons: this.props.wtData.incompleteReasons,
        workTicketAvailable: this.props.wtData.workTicketAvailable,
        closingNotesText: this.state.closingNotesText,
        waitingSubmitResponse: this.state.waitingSubmitResponse,
        handleClose: this.handleFinishDialogClose,
        handleInputChange: this.handleInputChange,
        handleSubmit: this.handleSubmit,
        handleOpen: this.handleFinishDialogOpen
      };
      returnhtml = (
        <RemoveHub
          history={this.props.history}
          user={this.props.user}
          unit={this.props.unit}
          match={this.props.match}
          callback={
            this.props.wtData.workTicketAvailable ? this.openFinishDialog : this.returnToUnits
          }
        >
          <FinishInstallDialog
            open={finishDialogProps.showFinishInstallDialog}
            incompleteOnly={true}
            {...finishDialogProps}
          />
        </RemoveHub>
      );
    } else if (this.props.updating) {
      returnhtml = (
        <>
          {hubConnectivityBanner}
          <HubUpdating
            history={this.props.history}
            user={this.props.user}
            unit={this.props.unit}
            match={this.props.match}
            devices={this.props.devices}
            initial={this.props.initial_status}
          />
        </>
      );
    } else if (this.props.rebooting) {
      const storedRebootString = hubRebootDateStorage(this.state.unit.hub_serial).get();
      if (!storedRebootString) {
        return returnhtml;
      }
      const apiRebootCompleted = currentBootTimeGreaterThanStored(
        storedRebootString,
        this.state.status.boot_time
      );
      const diffSecs = getDiffSecsBetweenNowAndDateString(storedRebootString);
      const completed = (diffSecs / REBOOT_MAX_DURATION_SECS) * 100;
      returnhtml = (
        <>
          {hubConnectivityBanner}
          <HubRebooting
            history={this.props.history}
            user={this.props.user}
            unit={this.props.unit}
            match={this.props.match}
            completed={completed}
            apiRebootCompleted={apiRebootCompleted}
          />
        </>
      );
    } else if (this.props.restarting) {
      const storedRestartString = hubRestartDateStorage(this.state.unit.hub_serial).get();
      if (!storedRestartString) {
        return returnhtml;
      }
      let diffSecs = getDiffSecsBetweenNowAndDateString(storedRestartString);
      let completed = (diffSecs / RESTART_MAX_DURATION_SECS) * 100;
      returnhtml = (
        <>
          {hubConnectivityBanner}
          <HubRestarting
            history={this.props.history}
            user={this.props.user}
            unit={this.props.unit}
            match={this.props.match}
            completed={completed}
            devices={this.props.devices}
          />
        </>
      );
    }
    return returnhtml;
  }
  render() {
    let detailPage = this.getReturn();
    const backgroundColor = this.showBackgroundColorForMainTab()
      ? { backgroundColor: '#F3F3F3', boxShadow: '0 48px 0 #F3F3F3' }
      : {};
    return (
      <MuiThemeProvider theme={blankTheme}>
        <div className="full-height" style={backgroundColor}>
          {this.state.page === 'detail' ? detailPage : ''}
          {this.state.page !== 'detail' ? this.getHubConnectivityBanner() : ''}
          {this.state.page === 'nest' ? this.getNestPage() : ''}
          {this.state.page === 'wifi_offline' ? this.getWifiOfflinePage() : ''}
          {this.state.page === 'inclusion' ? this.getInclusionPage(false) : ''}
          {this.state.page === 'exclusion' ? this.getInclusionPage(true) : ''}
        </div>
      </MuiThemeProvider>
    );
  }
}

export default withLDConsumer()(withStyles(styles, { withTheme: true })(UnitDetail));
