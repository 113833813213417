// TODO We have two very similar files: UnitDetail.js and UnitDetail_v2.tsx.
//  Look into removing UnitDetail.js and just using UnitDetail_v2.tsx from now on,
//  and whether we'd need to move UnitDetail.js' feature set into UnitDetail_v2.tsx or
//  if it can be removed altogether (work with UX team).
//  Having both files is resulting is code duplication, slower development time, higher probability of bugs, etc.
//  https://levelhome.atlassian.net/browse/MDU2-47

import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import * as Sentry from '@sentry/browser';
import SwipeableViews from 'react-swipeable-views';
import AssociateHub from './AssociateHub';
import RemoveHub from './RemoveHub';
import Header from '../../Common/Headers/Header';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import ZwaveList from './ZwaveList';
import WifiList from './WifiList';
import WifiOffline from './WifiOffline';
import HubInfoList from './HubInfoList';
import AddWifiDevice from './AddWifiDevice';
import Inclusion from './Inclusion';
import HubUpdating from './HubUpdating';
import HubRebooting from './HubRebooting';
import HubRestarting from './HubRestarting';
import {
  clearIntervals,
  currentBootTimeGreaterThanStored,
  getDiffSecsBetweenNowAndDateString,
  setFocusRequiredInterval
} from '../common/utils';
import { resetFabPosition } from '../../Common/utils/commonUtils';

import * as routes from '../unitdetail_apiRoutes';
import client from '../../Common/utils/client';
import * as deviceConstants from '../../Common/Constants/deviceConstants';
import { DEVICE_TYPES_RELATIVE_TO_YALE as yale } from '../../Common/Constants/deviceConstants';
import * as sfConstants from '../../Common/Constants/salesforceConstants';
import theme from '../../Common/Themes/tab_theme';
import '../../Common/stylesheets/global.scss';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import * as ldConstants from '../../Common/Constants/launchDarklyConstants';
import FinishInstallDialog from '../../Common/Popups/FinishInstallDialog';
import {
  HUB_CONNECTIVITY_BANNER,
  YALE_LOCK_MASTER_CODES
} from '../../Common/Constants/launchDarklyConstants';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import {
  setPendingWorkTicket,
  removePendingWorkTicket,
  hubRebootDateStorage,
  hubRestartDateStorage
} from '../../Common/utils/storageInterface';
import ReactGA from 'react-ga';
import { GOOGLE_ANALYTICS } from '../../Common/Constants/launchDarklyConstants';
import { SUBMIT_FROM_HUB_ASSOCIATION } from '../../Common/Constants/launchDarklyConstants';
import {
  HUB_STATUS_INTERVAL_TIMEOUT,
  REBOOT_MAX_DURATION_SECS,
  RESTART_MAX_DURATION_SECS
} from '../../Common/Constants/installerAppConstants';
import { HubConnectivityBanner } from '../../Common/HubConnectivity/HubConnectivityBanner';
import blankTheme from '../../Common/Themes/blankTheme';
import { captureExceptionIfNot424 } from '../../Common/utils/errorHandling';

function TabContainer({ children, dir }) {
  return (
    <Typography component="div" dir={dir} style={{ padding: '24px' }}>
      {children}
    </Typography>
  );
}

const styles = (theme) => ({
  button: {
    display: 'block',
    left: '50%',
    marginLeft: '-40px',
    bottom: '90px',
    position: 'fixed',
    backgroundColor: '#4ad4d4'
  },
  fab: {
    position: 'fixed',
    bottom: '16px',
    backgroundColor: '#4ad4d4'
  },
  orange: {
    backgroundColor: '#F6861E',
    boxShadow: '0 -6px 0 #F6861E, 0 1px 6px rgba(0,0,0, .35)'
  },
  textField: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    width: '100%'
  },
  tabSize: {
    fontSize: '16px'
  },
  include: {
    marginBottom: '64px'
  },
  exclude: {
    backgroundColor: '#991210'
  },
  finishButtonYale: {
    position: 'fixed',
    bottom: '16px',
    backgroundColor: '#4ad4d4'
  }
});

class UnitDetail extends Component {
  constructor(props) {
    super(props);
    const { classes, theme } = this.props;
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const cancel = {
      cancelToken: source.token
    };
    this.__source = source;
    this.__cancel = cancel;
    this.classes = classes;
    this.theme = theme;
    this.state = {
      unit: this.props.unit,
      devices: this.props.devices,
      value: 0,
      page: 'detail',
      alertInclude: false,
      alertExclude: false,
      showFinishInstallDialog: false,
      installStatusRadio: '',
      incompleteReason: '',
      privacyLockInstalled: false,
      addOnSwitchesInstalled: false,
      addOnSwitchesQty: '',
      transformerInstalled: false,
      hubLocationText: '',
      closingNotesText: '',
      waitingSubmitResponse: false,
      yaleLock: yale.UNKNOWN,
      codeSyncingData: this.props.codeSyncingData,
      status: {
        hub_serial_number: 'Loading...',
        gateway_id: this.props.unit.gatewayId,
        balena_commit_hash: 'Loading...',
        balena_branch: 'Loading...',
        balena_release_group: 'Loading...',
        boot_time: 'Loading...',
        internet_delivery: 'Loading...',
        ethernet_ip_address: 'Loading...',
        cellular_ip_address: 'Loading...',
        cellular_signal: 'Loading...',
        iccid: 'Loading...',
        last_seen: 'Loading...',
        ...this.props.hubStatus
      }
    };
  }

  closeDialog = () => {
    this.setState({ alertExclude: false, alertInclude: false });
  };

  refreshSensors = () => {
    client
      .get(routes.SENSOR(this.props.unit.hub_serial), this.__cancel)
      .then((res) => {
        let data = res.data.results;
        if (data.length && data !== this.state.devices) {
          this.setState(
            {
              devices: data
            },
            () => {
              this.setState({
                wifiDevice: this.getWifiDeviceOrNull()
              });
            }
          );
        }
      })
      .catch(function (thrown) {
        if (axios.isCancel(thrown)) {
          console.log('Request canceled', thrown.message);
        } else {
          Sentry.captureException(thrown);
          return false;
        }
      });
  };

  refreshUpdates = () => {
    client
      .get(routes.UPDATE_STATUS(this.props.unit.hub_serial), this.__cancel)
      .then((res) => {
        let data = res.data.result;
        if (data?.update_pending) {
          window.location.reload();
        }
        return Promise.resolve();
      })
      .catch(function (thrown) {
        if (axios.isCancel(thrown)) {
          console.log('Request canceled', thrown.message);
        } else {
          captureExceptionIfNot424(thrown);
          return false;
        }
      });
  };

  setAllIntervals() {
    const newState = {};
    if (!this.state.sensorInterval) {
      newState.sensorInterval = setFocusRequiredInterval(
        this.refreshSensors,
        parseInt(process.env.REACT_APP_SENSOR_POLL, 10)
      );
    }
    if (!this.state.updateStatusInterval) {
      newState.updateStatusInterval = setFocusRequiredInterval(this.refreshUpdates, 10000);
    }
    if (!this.state.hubStatusInterval) {
      newState.hubStatusInterval = this.createHubStatusInterval();
    }
    this.setState(newState);
  }

  createHubStatusInterval = () => {
    return setFocusRequiredInterval(this.getHubStatus, HUB_STATUS_INTERVAL_TIMEOUT);
  };

  getWifiDeviceOrNull() {
    if (this.state.devices.length === 0 || this.state.devices.length === 1) {
      return null;
    }
    let devices = this.state.devices;
    for (let i = 0; i < devices.length; i++) {
      if (deviceConstants.WIFI_DEVICES.includes(devices[i].model)) {
        return devices[i];
      }
    }
    return null;
  }

  componentDidMount() {
    resetFabPosition();
    let hubLocationText;
    const installerPreviewData = this.props.unit[
      sfConstants.SharedVars.INSTALLER_PREVIEW_DATA
    ];
    if (installerPreviewData) {
      hubLocationText = installerPreviewData[sfConstants.SharedVars.HUB_LOCATION];
    }

    // Set localstorage item to indicate that there is an open work ticket if work ticket is not complete or incomplete
    if (
      installerPreviewData &&
      this.props.unit.hub_serial &&
      this.props.flags[ldConstants.ENFORCE_WORK_TICKET_CLOSURE] &&
      !sfConstants.WorkTicket.SUBMITTED_STATUSES.includes(
        installerPreviewData[sfConstants.SharedVars.WORK_TICKET_STATUS]
      )
    ) {
      setPendingWorkTicket(
        this.props.unit.uid,
        this.props.unit.unit,
        this.props.unit.communityId,
        this.props.unit.communityName,
        `/community/${this.props.unit.communityId}/unit/${this.props.unit.uid}`,
        routes.GET_WORK_TICKET_DATA(this.props.unit.uid)
      );
    }

    this.setState({
      wifiDevice: this.getWifiDeviceOrNull(),
      hubLocationText: hubLocationText ? hubLocationText : ''
    });
    if (this.props.unit.hub_serial && this.props.devices.length) {
      if (!this.props.updating) {
        this.setAllIntervals();
      } else {
        this.setState({ hubStatusInterval: this.createHubStatusInterval() });
      }
    }

    // If on the AssociateHub component
    if (
      this.props.flags[SUBMIT_FROM_HUB_ASSOCIATION] &&
      this.props.unit.gatewayId !== undefined &&
      !this.props.unit.gatewayId
    ) {
      this.setState({ installStatusRadio: 'incomplete' });
    }
  }

  /**
   * This function is used for positioning finish button and fabs,
   * depending on the feature flag.
   * This function can be removed once we no longer use the feature flag.
   */
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.flags[YALE_LOCK_MASTER_CODES] !== this.props.flags[YALE_LOCK_MASTER_CODES]) {
      resetFabPosition();
    }
    if (
      prevProps.flags[YALE_LOCK_MASTER_CODES] &&
      this.props.flags[YALE_LOCK_MASTER_CODES] === false // don't want undefined
    ) {
      const finishButton = document.getElementById('finish-button');
      if (finishButton) {
        finishButton.style.marginLeft = '-40px';
      }
    }
  }

  componentWillUnmount() {
    clearIntervals([
      this.state.sensorInterval,
      this.state.updateStatusInterval,
      this.state.hubStatusInterval
    ]);
    if (this.props.unit.hub_serial && this.props.devices.length) {
      this.__source.cancel('Operation canceled by the user.');
    }
  }

  clearUnitDetailSpecificIntervals = () => {
    clearIntervals([this.state.sensorInterval, this.state.updateStatusInterval]);
    this.setState({
      sensorInterval: undefined,
      updateStatusInterval: undefined
    });
  };

  getHubStatus = () => {
    client
      .get(routes.HUB_STATUS(this.props.unit.hub_serial))
      .then((res) => {
        res.data.gateway_id = this.props.unit.gatewayId;
        this.setState({ status: res.data });
      })
      .catch((err) => {
        Sentry.captureException(err);
      });
  };

  handleChange = (event, value) => {
    this.setState({ value }, function () {
      resetFabPosition();
    });
  };

  handleChangeIndex = (index) => {
    this.setState({ value: index }, function () {
      resetFabPosition();
    });
  };

  handleFinishDialogClose = () => {
    this.setState({ showFinishInstallDialog: false });
  };

  handleFinishDialogOpen = () => {
    this.setState({ showFinishInstallDialog: true });
  };

  handleInputChange = (event) => {
    let { name, value, type, checked } = event.target;
    if (type === 'checkbox') {
      this.setState({ [name]: checked });
    } else {
      this.setState({
        [name]: value
      });
    }
  };

  handleSubmit = () => {
    if (!this.state.installStatusRadio) {
      alert('Please select Complete or Incomplete');
      return;
    } else if (
      this.state.installStatusRadio === 'incomplete' &&
      !this.state.incompleteReason
    ) {
      alert('Please select an Incomplete reason');
      return;
    }
    if (this.state.addOnSwitchesInstalled) {
      if (!this.state.addOnSwitchesQty) {
        alert('Please enter the quantity of add-on switches installed');
        return;
      } else if (!Number.isInteger(parseInt(this.state.addOnSwitchesQty))) {
        alert('The add-on switches quantity must be a whole number');
        return;
      } else if (this.state.addOnSwitchesQty <= 0) {
        alert('The add-on switches quantity must be greater than 0');
        return;
      }
    }
    if (!this.state.closingNotesText) {
      alert('Please fill in the Closing Notes');
      return;
    }

    let data = {};

    const installerPreviewData = this.props.unit[
      sfConstants.SharedVars.INSTALLER_PREVIEW_DATA
    ];

    if (installerPreviewData) {
      data[sfConstants.SharedVars.WORK_TYPE] =
        installerPreviewData[sfConstants.SharedVars.WORK_TYPE];
    } else {
      data[sfConstants.SharedVars.WORK_TYPE] = sfConstants.SharedVars.SERVICE;
    }

    data[sfConstants.SharedVars.WORK_TICKET_FIELDS] = {
      [sfConstants.WorkTicket.STATUS__C]: this.state.installStatusRadio,
      [sfConstants.WorkTicket.NOTES__C]: this.state.closingNotesText,
      [sfConstants.WorkTicket.DID_YOU_LEAVE_DEVICES_ON_SITE__C]: 'No',
      [sfConstants.WorkTicket.DID_YOU_TAKE_DEVICES_FROM_THE_SITE__C]: 'No' // both No's are hard coded for now
    };

    if (this.state.installStatusRadio === 'incomplete') {
      data[sfConstants.SharedVars.WORK_TICKET_FIELDS][
        sfConstants.WorkTicket.INCOMPLETE_REASON__C
      ] = this.state.incompleteReason;
    }

    data[sfConstants.SharedVars.UNIT_FIELDS] = {};

    if (this.state.hubLocationText) {
      data[sfConstants.SharedVars.UNIT_FIELDS][
        sfConstants.Unit.HUB_LOCATION__C
      ] = this.state.hubLocationText;
    }
    const devicesToBeInstalled = this.props.wtData[
      sfConstants.SharedVars.DEVICES_TO_BE_INSTALLED
    ];

    const { community } = this.props;
    const { SALESFORCE_INFO } = sfConstants.SharedVars;
    const { ID } = sfConstants.SharedVars;

    if (this.state.privacyLockInstalled) {
      let privacyLock = devicesToBeInstalled.find((device) => {
        return (
          device[sfConstants.SharedVars.DEVICE_TYPE] === sfConstants.DeviceTypes.PRIVACY_LOCK
        );
      });

      if (privacyLock) {
        privacyLock = privacyLock[sfConstants.SharedVars.ID];
      } else {
        const { PRIVACY_LOCK } = sfConstants.SharedVars;

        if (
          community &&
          community[SALESFORCE_INFO] &&
          community[SALESFORCE_INFO][PRIVACY_LOCK] &&
          community[SALESFORCE_INFO][PRIVACY_LOCK][ID]
        ) {
          privacyLock = community[SALESFORCE_INFO][PRIVACY_LOCK][ID];
        } else {
          privacyLock = sfConstants.DEFAULT_PRIVACY_LOCK_SKU;
        }
      }

      data[sfConstants.SharedVars.UNIT_FIELDS][sfConstants.Unit.PRIVACY_LOCK_QTY__C] = 1;
      data[sfConstants.SharedVars.UNIT_FIELDS][sfConstants.Unit.PRIVACY_LOCK__C] = privacyLock;
    }

    if (this.state.addOnSwitchesInstalled) {
      data[sfConstants.SharedVars.UNIT_FIELDS][
        sfConstants.Unit.ADD_ON_SWITCH_QTY__C
      ] = this.state.addOnSwitchesQty;

      let addOnSwitch = devicesToBeInstalled.find((device) => {
        return (
          device[sfConstants.SharedVars.DEVICE_TYPE] === sfConstants.DeviceTypes.ADD_ON_SWITCH
        );
      });

      if (addOnSwitch) {
        addOnSwitch = addOnSwitch[sfConstants.SharedVars.ID];
      } else {
        const { ADD_ON_SWITCH } = sfConstants.SharedVars;

        if (
          community &&
          community[SALESFORCE_INFO] &&
          community[SALESFORCE_INFO][ADD_ON_SWITCH] &&
          community[SALESFORCE_INFO][ADD_ON_SWITCH][ID]
        ) {
          addOnSwitch = community[SALESFORCE_INFO][ADD_ON_SWITCH][ID];
        } else {
          addOnSwitch = sfConstants.DEFAULT_ADD_ON_SWITCH_SKU;
        }
      }

      data[sfConstants.SharedVars.UNIT_FIELDS][
        sfConstants.Unit.ADD_ON_SWITCH_SKU__C
      ] = addOnSwitch;
    }

    // For each array, the first index is the sku field, and second is the quantity field.
    const deviceTypeToUnitFields = {
      [sfConstants.DeviceTypes.LOCK]: [sfConstants.Unit.LOCK__C, sfConstants.Unit.LOCK_QTY__C],
      [sfConstants.DeviceTypes.THERMOSTAT]: [
        sfConstants.Unit.THERMOSTAT__C,
        sfConstants.Unit.THERMOSTAT_QTY__C
      ],
      [sfConstants.DeviceTypes.PRIMARY_SWITCH]: [
        sfConstants.Unit.PIMARY_SWITCH_SKU__C,
        sfConstants.Unit.PRIMARY_SWITCHES_QTY__C
      ],
      [sfConstants.DeviceTypes.OUTLET]: [
        sfConstants.Unit.OUTLET__C,
        sfConstants.Unit.OUTLET_QTY__C
      ],
      [sfConstants.DeviceTypes.VOICE_CONTROL]: [
        sfConstants.Unit.VOICE_CONTROL__C,
        sfConstants.Unit.VOICE_CONTROL_QTY__C
      ]
    };

    let deviceQuantities = {}; // key is the dwelo device type, value is an int

    // populates deviceQuantities
    if (this.props.flags[ldConstants.SALESFORCE_UNIT_SKU_QUANTITY]) {
      this.state.devices.forEach((device) => {
        if (device.deviceType !== deviceConstants.GATEWAY) {
          if (device.deviceType in deviceQuantities) {
            deviceQuantities[device.deviceType]++;
          } else {
            deviceQuantities[device.deviceType] = 1;
          }
        }
      });
    }

    // Adds the devices that were "installed" to the post data
    // to be sent to the Salesforce unit record.
    devicesToBeInstalled.forEach((device) => {
      const salesforceDeviceType = device[sfConstants.SharedVars.DEVICE_TYPE];
      if (salesforceDeviceType && deviceTypeToUnitFields[salesforceDeviceType]) {
        const skuField = deviceTypeToUnitFields[salesforceDeviceType][0];
        const quantityField = deviceTypeToUnitFields[salesforceDeviceType][1];
        const quantity = this.props.flags[ldConstants.SALESFORCE_UNIT_SKU_QUANTITY]
          ? deviceQuantities[sfConstants.SalesforceDweloMappings[salesforceDeviceType]] // quantity from z-wave list component
          : device[sfConstants.SharedVars.QUANTITY]; // quantity from salesforce work ticket

        if (quantity) {
          data[sfConstants.SharedVars.UNIT_FIELDS][skuField] =
            device[sfConstants.SharedVars.ID];
          data[sfConstants.SharedVars.UNIT_FIELDS][quantityField] = quantity;
        }
      }
    });

    // Adds the devices that were "installed" to the post data
    // to be sent to the Salesforce unit record.
    devicesToBeInstalled.forEach((device) => {
      const deviceType = device[sfConstants.SharedVars.DEVICE_TYPE];
      if (deviceType && deviceTypeToUnitFields[deviceType]) {
        const skuField = deviceTypeToUnitFields[deviceType][0];
        const quantityField = deviceTypeToUnitFields[deviceType][1];
        data[sfConstants.SharedVars.UNIT_FIELDS][skuField] = device[sfConstants.SharedVars.ID];
        data[sfConstants.SharedVars.UNIT_FIELDS][quantityField] =
          device[sfConstants.SharedVars.QUANTITY];
      }
    });

    this.setState({ waitingSubmitResponse: true });

    this.submitWorkTicket(data);
  };

  submitWorkTicket(data) {
    const unitId = this.props.match.params.unitId;
    client
      .post(routes.SUBMIT_WORK_TICKET(unitId), data)
      .then((res) => {
        const communityId = this.props.match.params.communityId;
        this.props.history.push('/community/' + communityId);
        removePendingWorkTicket();

        if (this.props.flags[GOOGLE_ANALYTICS]) {
          ReactGA.event({
            category: 'Unit',
            action: 'Install Submitted',
            label: 'Community: ' + this.props.match.params.communityId + ', Unit: ' + unitId
          });
        }
      })
      .catch((res) => {
        this.setState({ waitingSubmitResponse: false });
        Sentry.captureException(res);
        alert('An error occurred. Please try again.');
        if (this.props.flags[GOOGLE_ANALYTICS]) {
          ReactGA.event({
            category: 'Unit',
            action: 'Install Submission Failed',
            label:
              'Community: ' +
              this.props.match.params.communityId +
              ', Unit: ' +
              unitId +
              ', ErrorCode: ' +
              res.status +
              ', Error: ' +
              res.statusText
          });
        }
      });
  }

  addWifiDevice = (e) => {
    e.preventDefault();
    this.clearUnitDetailSpecificIntervals();
    this.setState({ page: 'nest' });
  };

  getWifiOffline = () => {
    this.clearUnitDetailSpecificIntervals();
    this.setState({ page: 'wifi_offline' });
  };

  determineYaleLock = (elementName) => {
    if (elementName === yale.UNKNOWN) {
      return yale.UNKNOWN;
    }

    // if 'yale' is in the device model name from salesforce
    return elementName.toLowerCase().indexOf('yale') === -1 ? yale.NOT_YALE : yale.IS_YALE;
  };

  startInclusion = (e) => {
    e.preventDefault();
    this.clearUnitDetailSpecificIntervals();
    let newState = { page: 'inclusion' };
    if (this.props.flags[YALE_LOCK_MASTER_CODES]) {
      newState['yaleLock'] = this.determineYaleLock(e.currentTarget.name);
    }
    this.setState(newState);
  };

  startExclusion = (e) => {
    this.clearUnitDetailSpecificIntervals();
    let newState = { page: 'exclusion' };
    if (this.props.flags[YALE_LOCK_MASTER_CODES]) {
      newState['yaleLock'] = this.determineYaleLock(e.currentTarget.name);
    }
    this.setState(newState);
  };

  returnFunction = (value = 0, device = null, removeId = null) => {
    this.setAllIntervals();
    if (!device && !removeId) {
      this.setState({ page: 'detail', value: value }, function () {
        resetFabPosition();
      });
    } else if (device) {
      let devices = this.state.devices;
      devices.push(device);
      this.setState(
        {
          page: 'detail',
          devices: devices,
          wifiDevice: device,
          value: value
        },
        function () {
          resetFabPosition();
        }
      );
    } else if (removeId) {
      let newDevices = this.state.devices.filter(function (obj) {
        return obj.uid !== removeId;
      });
      this.setState(
        {
          page: 'detail',
          devices: newDevices,
          wifiDevice: null,
          value: value
        },
        function () {
          resetFabPosition();
        }
      );
    }
  };
  openFinishDialog = () => this.setState({ showFinishInstallDialog: true });
  returnToUnits = () => {
    const communityId = this.props.match.params.communityId;
    const installerPreviewData = this.props.unit[
      sfConstants.SharedVars.INSTALLER_PREVIEW_DATA
    ];
    if (
      installerPreviewData &&
      installerPreviewData[sfConstants.SharedVars.WORK_TICKET_STATUS] !==
        sfConstants.WorkTicket.COMPLETE
    ) {
      this.handleFinishDialogOpen();
    } else {
      this.props.history.push('/community/' + communityId);
    }
  };
  getNestPage() {
    return (
      <AddWifiDevice
        history={this.props.history}
        user={this.props.user}
        unit={this.props.unit}
        device={this.state.wifiDevice}
        return={this.returnFunction}
      />
    );
  }

  getWifiOfflinePage = () => {
    return (
      <WifiOffline
        history={this.props.history}
        user={this.props.user}
        unit={this.props.unit}
        device={this.state.wifiDevice}
        return={this.returnFunction}
      />
    );
  };

  getInclusionPage(exclusion) {
    return (
      <Inclusion
        history={this.props.history}
        user={this.props.user}
        unit={this.props.unit}
        devices={this.state.devices}
        return={this.returnFunction}
        exclusion={exclusion}
        yaleLock={this.state.yaleLock}
        sfConfiguration={this.props.sfConfiguration}
        isIotHub={this.props.hubStatus?.is_iot_hub ?? false}
      />
    );
  }

  getInclusionFab = () => {
    return (
      <Fab
        color="primary"
        aria-label="Include"
        className={this.classes.fab + ' keep-fixed ' + this.classes.include}
        onClick={this.startInclusion}
        style={{ display: this.state.value === 0 ? 'block' : 'none' }}
      >
        <AddIcon />
      </Fab>
    );
  };

  getExclusionFab = () => {
    return (
      <Fab
        aria-label="Exclude"
        className={this.classes.fab + ' keep-fixed'}
        onClick={this.startExclusion}
        style={{ display: this.state.value === 0 ? 'block' : 'none' }}
      >
        <RemoveIcon />
      </Fab>
    );
  };

  getHubConnectivityBanner = () => {
    if (this.props.flags[HUB_CONNECTIVITY_BANNER]) {
      return (
        <HubConnectivityBanner
          cellularSignal={
            Number.isInteger(this.state.status.cellular_signal)
              ? this.state.status.cellular_signal
              : null
          }
          connectedToCellular={!!this.state.status.cellular_ip_address}
          connectedToEthernet={!!this.state.status.ethernet_ip_address}
          connectedToWiFi={!!this.state.status.wifi_ip_address}
        />
      );
    } else {
      return null;
    }
  };

  getReturn() {
    const { [YALE_LOCK_MASTER_CODES]: yaleFlag } = this.props.flags;
    const inclusionFab = !yaleFlag ? this.getInclusionFab() : null;
    const exclusionFab = !yaleFlag ? this.getExclusionFab() : null;

    const hubConnectivityBanner = this.getHubConnectivityBanner();

    let addWifiDeviceClasses = yaleFlag ? `${this.classes.include} ` : '';
    addWifiDeviceClasses += `${this.classes.fab} keep-fixed`;

    const finishButtonClasses = yaleFlag
      ? `keep-fixed ${this.classes.fab}`
      : `${this.classes.button}`;

    let finishButton;
    let finishInstallDialog;

    if (this.props.wtData.workTicketAvailable) {
      finishButton = (
        <Button
          id={'finish-button'}
          variant="contained"
          size="medium"
          onClick={this.handleFinishDialogOpen}
          className={finishButtonClasses}
        >
          Finish
        </Button>
      );
      finishInstallDialog = (
        <FinishInstallDialog
          open={this.state.showFinishInstallDialog}
          installStatusRadio={this.state.installStatusRadio}
          incompleteReason={this.state.incompleteReason}
          incompleteReasons={this.props.wtData.incompleteReasons}
          privacyLockInstalled={this.state.privacyLockInstalled}
          addOnSwitchesInstalled={this.state.addOnSwitchesInstalled}
          addOnSwitchesQty={this.state.addOnSwitchesQty}
          transformerInstalled={this.state.transformerInstalled}
          hubLocationText={this.state.hubLocationText}
          closingNotesText={this.state.closingNotesText}
          waitingSubmitResponse={this.state.waitingSubmitResponse}
          handleClose={this.handleFinishDialogClose}
          handleInputChange={this.handleInputChange}
          handleSubmit={this.handleSubmit}
        />
      );
    }

    let returnhtml = (
      <div>
        <MuiThemeProvider theme={theme}>
          {hubConnectivityBanner}
          <Header
            elevation={true}
            headerText={this.props.unit.unit}
            back={true}
            return={this.returnToUnits}
            arrow={true}
            user={this.props.user}
            history={this.props.history}
            unit={this.props.unit}
            community={this.props.community}
            unitmenu={this.state.value}
          />
          <Paper square className={this.classes.orange} elevation={0}>
            <Tabs
              value={this.state.value}
              onChange={this.handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
            >
              <Tab label="DEVICES" classes={{ root: this.classes.tabSize }} />
              <Tab label="NEST" classes={{ root: this.classes.tabSize }} />
              <Tab label="HUB" classes={{ root: this.classes.tabSize }} />
            </Tabs>
          </Paper>
          <SwipeableViews
            axis={this.theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={this.state.value}
            onChangeIndex={this.handleChangeIndex}
            containerStyle={{
              // Workaround until https://github.com/oliviertassinari/react-swipeable-views/issues/599 is fixed
              transition: 'transform 0.35s cubic-bezier(0.15, 0.3, 0.25, 1) 0s'
            }}
          >
            <TabContainer dir={this.theme.direction}>
              <ZwaveList
                history={this.props.history}
                devices={this.state.devices}
                devicesToBeInstalled={
                  this.props.wtData[sfConstants.SharedVars.DEVICES_TO_BE_INSTALLED]
                }
                unit={this.props.unit}
                community={this.props.community}
                startInclusion={this.startInclusion}
                startExclusion={this.startExclusion}
                codeSyncingData={this.props.codeSyncingData}
              />
            </TabContainer>
            <TabContainer dir={this.theme.direction}>
              <WifiList
                history={this.props.history}
                device={this.state.wifiDevice}
                unit={this.props.unit}
                onClick={this.addWifiDevice}
                offline={this.getWifiOffline}
              />
            </TabContainer>
            <TabContainer dir={this.theme.direction}>
              <HubInfoList status={this.state.status} />
            </TabContainer>
          </SwipeableViews>
        </MuiThemeProvider>
        {inclusionFab}
        {exclusionFab}
        <Fab
          color="primary"
          aria-label="Add"
          className={addWifiDeviceClasses}
          onClick={this.addWifiDevice}
          style={{
            display: this.state.value === 1 && !this.state.wifiDevice ? 'block' : 'none'
          }}
        >
          <AddIcon />
        </Fab>
        {finishButton}
        {finishInstallDialog}
      </div>
    );
    if (this.props.unit.gatewayId !== undefined && !this.props.unit.gatewayId) {
      let finishDialogProps = {};
      if (this.props.flags[ldConstants.SUBMIT_FROM_HUB_ASSOCIATION]) {
        finishDialogProps = {
          showFinishInstallDialog: this.state.showFinishInstallDialog,
          installStatusRadio: this.state.installStatusRadio,
          incompleteReason: this.state.incompleteReason,
          incompleteReasons: this.props.wtData.incompleteReasons,
          workTicketAvailable: this.props.wtData.workTicketAvailable,
          closingNotesText: this.state.closingNotesText,
          waitingSubmitResponse: this.state.waitingSubmitResponse,
          handleClose: this.handleFinishDialogClose,
          handleInputChange: this.handleInputChange,
          handleSubmit: this.handleSubmit,
          handleOpen: this.handleFinishDialogOpen
        };
      }
      returnhtml = (
        <AssociateHub
          history={this.props.history}
          user={this.props.user}
          unit={this.props.unit}
          match={this.props.match}
          {...finishDialogProps}
        />
      );
    } else if (this.props.unit.gatewayId && !this.props.unit.hub_serial) {
      if (this.state.installStatusRadio !== 'incomplete') {
        this.setState({ installStatusRadio: 'incomplete' });
      }
      const finishDialogProps = {
        showFinishInstallDialog: this.state.showFinishInstallDialog,
        installStatusRadio: this.state.installStatusRadio,
        incompleteReason: this.state.incompleteReason,
        incompleteReasons: this.props.wtData.incompleteReasons,
        workTicketAvailable: this.props.wtData.workTicketAvailable,
        closingNotesText: this.state.closingNotesText,
        waitingSubmitResponse: this.state.waitingSubmitResponse,
        handleClose: this.handleFinishDialogClose,
        handleInputChange: this.handleInputChange,
        handleSubmit: this.handleSubmit,
        handleOpen: this.handleFinishDialogOpen
      };
      returnhtml = (
        <RemoveHub
          history={this.props.history}
          user={this.props.user}
          unit={this.props.unit}
          match={this.props.match}
          callback={
            this.props.wtData.workTicketAvailable ? this.openFinishDialog : this.returnToUnits
          }
        >
          <FinishInstallDialog
            open={finishDialogProps.showFinishInstallDialog}
            incompleteOnly={true}
            {...finishDialogProps}
          />
        </RemoveHub>
      );
    } else if (this.props.updating) {
      returnhtml = (
        <>
          {hubConnectivityBanner}
          <HubUpdating
            history={this.props.history}
            user={this.props.user}
            unit={this.props.unit}
            match={this.props.match}
            devices={this.props.devices}
            initial={this.props.initial_status}
          />
        </>
      );
    } else if (this.props.rebooting) {
      const storedRebootString = hubRebootDateStorage(this.state.unit.hub_serial).get();
      if (!storedRebootString) {
        return returnhtml;
      }
      const apiRebootCompleted = currentBootTimeGreaterThanStored(
        storedRebootString,
        this.state.status.boot_time
      );
      const diffSecs = getDiffSecsBetweenNowAndDateString(storedRebootString);
      const completed = (diffSecs / REBOOT_MAX_DURATION_SECS) * 100;
      returnhtml = (
        <>
          {hubConnectivityBanner}
          <HubRebooting
            history={this.props.history}
            user={this.props.user}
            unit={this.props.unit}
            match={this.props.match}
            completed={completed}
            apiRebootCompleted={apiRebootCompleted}
          />
        </>
      );
    } else if (this.props.restarting) {
      const storedRestartString = hubRestartDateStorage(this.state.unit.hub_serial).get();
      if (!storedRestartString) {
        return returnhtml;
      }
      let diffSecs = getDiffSecsBetweenNowAndDateString(storedRestartString);
      let completed = (diffSecs / RESTART_MAX_DURATION_SECS) * 100;
      returnhtml = (
        <>
          {hubConnectivityBanner}
          <HubRestarting
            history={this.props.history}
            user={this.props.user}
            unit={this.props.unit}
            match={this.props.match}
            completed={completed}
            devices={this.props.devices}
          />
        </>
      );
    }
    return returnhtml;
  }
  render() {
    let detailPage = this.getReturn();
    return (
      <MuiThemeProvider theme={blankTheme}>
        <div>
          {this.state.page === 'detail' ? detailPage : ''}
          {this.state.page !== 'detail' ? this.getHubConnectivityBanner() : ''}
          {this.state.page === 'nest' ? this.getNestPage() : ''}
          {this.state.page === 'wifi_offline' ? this.getWifiOfflinePage() : ''}
          {this.state.page === 'inclusion' ? this.getInclusionPage(false) : ''}
          {this.state.page === 'exclusion' ? this.getInclusionPage(true) : ''}
        </div>
      </MuiThemeProvider>
    );
  }
}

export default withLDConsumer()(withStyles(styles, { withTheme: true })(UnitDetail));
