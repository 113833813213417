export const GOOGLE_ANALYTICS = 'googleAnalytics';

export const YALE_LOCK_MASTER_CODES = 'yale-lock-master-codes';

export const SALESFORCE_UNIT_SKU_QUANTITY = 'salesforce-unit-sku-quantity';

export const ENFORCE_WORK_TICKET_CLOSURE = 'enforce-work-ticket-closure';

export const SUBMIT_FROM_HUB_ASSOCIATION = 'submit-from-hub-association';

export const UNIT_VERSION_2 = 'unit-version-2';

export const HANDLE_S2_INCLUSION_ATTEMPTS = 'handle-s-2-inclusion-attempts';

// Enables/Disables the need to wait for codes to sync
// before doing any other actions such as pairing devices.
export const WAIT_FOR_CODES_TO_SYNC = 'wait-for-codes-to-sync';

// Workaround for a current bug in our api.
// Customer support also needs the installer role to enter units in the installer app.
// This is a quick (and temp) fix to be able to tell if the logged in user is customer support.
export const CUSTOMER_SUPPORT_USER = 'customer-support-user';

// When on, this flag will allow more fields (related to door locks) to be sent to salesforce.
export const SUBMIT_EXTRA_LOCK_DATA = 'submit-extra-lock-data';

// Controls which select component and options are shown in the device naming/renaming dropdowns.
export const USE_SALESFORCE_INSTALL_LOCATIONS = 'use-salesforce-install-locations';

export const HUB_CONNECTIVITY_BANNER = 'hub-connectivity-banner';

export const DOOR_CODE_RELIABILITY = 'door-code-reliability';

export const TEST_CLIENT_SIDE_ID = '5e18b81ceba33d086e58a13d';
export const PROD_CLIENT_SIDE_ID = '5e18b81ceba33d086e58a13e';
