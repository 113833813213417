import ReactDOM from 'react-dom';

import './Common/stylesheets/global.scss';
import * as serviceWorker from './Common/utils/serviceWorker';
import * as Sentry from '@sentry/browser';
import viewportHack from './Common/scripts/viewportHack';
import ReactGA from 'react-ga';
import { makeApp } from './App';

ReactGA.initialize('UA-93339702-3');

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: process.env.REACT_APP_RELEASE_SHA,
    environment: process.env.REACT_APP_ENVIRONMENT,
    ignoreErrors: [
      // https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062
      // This exact string comes from Microsoft code and can be ignored.
      // The exception seem to be coming from a Microsoft email link crawler.
      /Object Not Found Matching Id/
    ]
  });
  Sentry.configureScope((scope) => {
    scope.setTag('dwelo_api_host', process.env.REACT_APP_API_HOST);
  });
}

function prepareMSW() {
  if (process.env.NODE_ENV === 'development') {
    const { worker } = require('./mocks/browser');
    return worker.start({
      onUnhandledRequest: 'bypass'
    });
  }
  return Promise.resolve();
}

(async () => {
  await prepareMSW();
  const App = await makeApp();
  ReactDOM.render(App, document.getElementById('root'), function () {
    // viewport hack for mobile
    viewportHack();
  });

  if (process.env.NODE_ENV === 'production') {
    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://bit.ly/CRA-PWA
    serviceWorker.unregister();
  }
})();
